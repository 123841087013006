//企业端资金管理
import { axios } from '@/utils/request'



//消息提醒
export function indexDataCount(parameter) {
  return axios({
    url: '/order/dataCount',
    method: 'post',
    data: parameter,
  })
}
