import { axios } from './request'
import { saveAs } from 'file-saver'

export function timeFix() {
  const time = new Date()
  const hour = time.getHours()
  return hour < 9 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 20 ? '下午好' : '晚上好'
}

export function welcome() {
  const arr = ['休息一会儿吧', '准备吃什么呢?', '要不要打一把 LOL', '我猜你可能累了']
  const index = Math.floor(Math.random() * arr.length)
  return arr[index]
}

/**
 * 触发 window.resize
 */
export function triggerWindowResizeEvent() {
  const event = document.createEvent('HTMLEvents')
  event.initEvent('resize', true, true)
  event.eventType = 'message'
  window.dispatchEvent(event)
}

export function handleScrollHeader(callback) {
  let timer = 0

  let beforeScrollTop = window.pageYOffset
  callback = callback || function () {}
  window.addEventListener(
    'scroll',
    (event) => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        let direction = 'up'
        const afterScrollTop = window.pageYOffset
        const delta = afterScrollTop - beforeScrollTop
        if (delta === 0) {
          return false
        }
        direction = delta > 0 ? 'down' : 'up'
        callback(direction)
        beforeScrollTop = afterScrollTop
      }, 50)
    },
    false
  )
}

export function isIE() {
  const bw = window.navigator.userAgent
  const compare = (s) => bw.indexOf(s) >= 0
  const ie11 = (() => 'ActiveXObject' in window)()
  return compare('MSIE') || ie11
}

/**
 * Remove loading animate
 * @param id parent element id or class
 * @param timeout
 */
export function removeLoadingAnimate(id = '', timeout = 1500) {
  if (id === '') {
    return
  }
  setTimeout(() => {
    document.body.removeChild(document.getElementById(id))
  }, timeout)
}

export function NoToChinese(num) {
  if (!/^\d*(\.\d*)?$/.test(num)) {
    alert('Number is wrong!')
    return 'Number is wrong!'
  }
  var AA = new Array('零', '一', '二', '三', '四', '五', '六', '七', '八', '九')
  var BB = new Array('', '十', '百', '千', '万', '亿', '点', '')
  var a = ('' + num).replace(/(^0*)/g, '').split('.'),
    k = 0,
    re = ''
  for (var i = a[0].length - 1; i >= 0; i--) {
    switch (k) {
      case 0:
        re = BB[7] + re
        break
      case 4:
        if (!new RegExp('0{4}\\d{' + (a[0].length - i - 1) + '}$').test(a[0])) re = BB[4] + re
        break
      case 8:
        re = BB[5] + re
        BB[7] = BB[5]
        k = 0
        break
    }
    if (k % 4 == 2 && a[0].charAt(i + 2) != 0 && a[0].charAt(i + 1) == 0) re = AA[0] + re
    if (a[0].charAt(i) != 0) re = AA[a[0].charAt(i)] + BB[k % 4] + re
    k++
  }
  if (a.length > 1) {
    //加上小数部分(如果有小数部分)
    re += BB[6]
    for (var i = 0; i < a[1].length; i++) re += AA[a[1].charAt(i)]
  }
  if (num > 9 && num < 20) {
    re = re.substring(1, re.length)
  }
  return re
}

export function secondsToTime(second) {
  var h = 0,
    m = 0,
    s = 0
  if (second >= 3600) {
    h = parseInt(second / 3600)
    second = second - h * 3600
  }
  m = parseInt(second / 60)
  second = second - m * 60
  s = second
  if (h > 0) {
    return h + '小时' + m + '分钟'
  } else if (m > 0) {
    return m + '分钟' + s + '秒'
  } else {
    return s + '秒'
  }
}

export function createPassword() {
  let chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
  /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
  let maxPos = chars.length
  let password = ''
  for (let i = 0; i < 6; i++) {
    password += chars.charAt(Math.floor(Math.random() * maxPos))
  }
  return password
}

export function download(url) {
  if (!url && typeof url !== 'string') return

  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        responseType: 'blob',
      })
      .then((res) => {
        console.log('blob', res, res.data.type)
        if (res.status === 200 && res.data) {
          const blob = new Blob([res.data])

          let fileName = createPassword() + Date.now()
          let index = url.lastIndexOf('.')
          console.log(index)
          if (index > -1) {
            fileName += '.' + url.substring(index, url.length)
          }
          saveAs(blob, fileName)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}


/**
 * 文件排序
 * @param files - 文件数组
 */
export function sortFiles(files) {
  if(!files||!Array.isArray(files)){
    return []
  }
  const regexp = /[^\d]+|\d+/g;
  let newFiles = [];
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    const weights = file.name.match(regexp);
    if (weights) {
      file.weights = weights;
      newFiles.push(file);
    }
  }
  return  newFiles.sort((a, b) => {
    let pos = 0;
    const weightsA = a.weights;
    const weightsB = b.weights;
    let weightA = weightsA[pos];
    let weightB = weightsB[pos];
    while (weightA && weightB) {
      const v = weightA - weightB;
      if (!isNaN(v) && v !== 0) return v;
      if (weightA !== weightB) return weightA > weightB ? 1 : -1;
      pos += 1;
      weightA = weightsA[pos];
      weightB = weightsB[pos];
    }
    return weightA ? 1 : -1;
  });


}


