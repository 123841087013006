//举报接口
import { axios } from '@/utils/request'
/*
 * @Description:
 * @Version:
 * @Author: 彭勃
 */

//举报分页查询
export function reportPageList(parameter) {
  return axios({
    url: '/report/page',
    method: 'post',
    params: parameter.page,
    data: parameter.queryParam,
  })
}

//举报-审核
export function reportAudit(parameter) {
  return axios({
    url: '/report/audit',
    method: 'post',
    data: parameter,
  })
}

//举报详情
export function reportDetail(parameter) {
  return axios({
    url: '/report/detail',
    method: 'post',
    params: parameter,
  })
}

//举报删除
export function reportDelete(parameter) {
  return axios({
    url: '/report/delete',
    method: 'post',
    data: parameter,
  })
}

//商家管理分页查询
export function storeAdminPageList(parameter) {
  return axios({
    url: '/store/page',
    method: 'post',
    params: parameter.page,
    data: parameter.queryParam,
  })
}

//设置商家抽成比例
export function storeAdminSetAgentPlatRatio(parameter) {
  return axios({
    url: '/store/setAgentPlatRatio',
    method: 'post',
    data: parameter,
  })
}

//商家管理详情
export function storeAdminDetail(parameter) {
  return axios({
    url: '/store/storeDetail',
    method: 'post',
    params: parameter,
  })
}

//商家管理修改状态
export function storeAdminDelete(parameter) {
  return axios({
    url: '/store/delete',
    method: 'post',
    data: parameter,
  })
}

/**
 * 模块名: 商家信息编辑
 * 代码描述:
 * 作者:陈莉莉
 * 创建时间:2022-08-19 09:10:37
 */
export function editStoreAdmin(parameter) {
  return axios({
    url: '/store/edit',
    method: 'POST',
    data: parameter,
  })
}

/**
 * 模块名:
 * 代码描述: 商家直播解禁
 * 作者:陈莉莉
 * 创建时间:2022-09-27 14:18:15
 */
export function releaseLiveForbidden(parameter) {
  return axios({
    url: '/store/liftingBroadcasting',
    method: 'POST',
    params: parameter,
  })
}
