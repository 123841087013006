import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/'
import { VueAxios } from './utils/request'
import './utils/rem'
//
import 'swiper/dist/css/swiper.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper)

import bootstrap from './core/bootstrap'
import './core/lazy_use'
import './permission' // permission control
import './utils/filter' // global filter
import './components/global.less'
import { Dialog } from '@/components'
import { hasBtnPermission } from './utils/permissions' // button permission
import { sysApplication } from './utils/applocation'
import vuescroll from 'vuescroll' //引入vuescroll
import 'vuescroll/dist/vuescroll.css' //引入vuescroll样式
import dayjs from 'dayjs'
import bbo from 'bbo'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

Vue.prototype.$dayjs = dayjs
Vue.prototype.$bbo = bbo

//图片预览插件
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import { v4 as uuidv4 } from 'uuid'

Vue.use(VueAxios)
Vue.use(Dialog)
Vue.use(vuescroll)
Vue.prototype.uuidv4 = uuidv4
Vue.prototype.hasPerm = hasBtnPermission
Vue.prototype.applocation = sysApplication
Vue.config.productionTip = false

Vue.use(Viewer)
Viewer.setDefaults({
  Options: {
    inline: true,
    button: true,
    navbar: true,
    title: true,
    toolbar: false,
    tooltip: true,
    movable: true,
    zoomable: true,
    rotatable: true,
    scalable: true,
    transition: true,
    fullscreen: true,
    keyboard: true,
    url: 'data-source',
  },
})

new Vue({
  router,
  store,
  created: bootstrap,
  render: (h) => h(App),
}).$mount('#app')
