<template>
  <div>
    <a-config-provider :locale="locale">
      <div id="app">
        <router-view />
      </div>
    </a-config-provider>
    <div class="notification-content" v-if="showPopup">
      <div class="notification-top-content">
        <div>通知</div>
        <div class="close-icon-size" @click="showPopup = false">×</div>
      </div>
      <div>有新的举报信息，是否立即查看</div>
      <div class="btn-content">
        <div class="btn-content-btn" @click="toLookReport">立即查看</div>
      </div>
    </div>
  </div>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import { AppDeviceEnquire } from '@/utils/mixin'
import '@/assets/animate.css'
import { reportPageList } from '@/api/modular/mallLiving/report'
import Vue, { h } from 'vue'
import { ACCESS_TOKEN, REPORT_TOTAL_NUMBER } from '@/store/mutation-types'
export default {
  mixins: [AppDeviceEnquire],
  data() {
    return {
      locale: zhCN,
      timer: null,
      total: 0,
      showPopup: false,
    }
  },
  methods: {
    initTableData() {
      const access_token = Vue.ls.get(ACCESS_TOKEN),
        report_number = Vue.ls.get(REPORT_TOTAL_NUMBER)
      if (access_token) {
        let page = {
          pageNo: 1,
          pageSize: 10,
        }
        let query = {
          page: page,
          queryParam: { mobile: '' },
        }
        reportPageList(query)
          .then((res) => {
            if (res.success) {
              this.total = res.data.totalRows
              if (report_number && report_number < this.total) {
                this.showPopup = true
              }
              Vue.ls.set(REPORT_TOTAL_NUMBER, this.total)
            }
          })
          .finally((res) => {})
      } else {
        return
      }
    },

    toLookReport() {
      this.showPopup = false
      this.$router.push('/sys_report')
    },
  },
}
</script>

<style>
.notification-content {
  position: fixed;
  top: 100px;
  right: 50px;
  width: 250px;
  background-color: #fff;
  z-index: 999;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 1px 2px rgba(24, 144, 255, 0.2);
}
.notification-top-content {
  display: flex;
  justify-content: space-between;
  height: 30px;
  line-height: 30px;
  height: 30px;
  margin-bottom: 13px;
}
.close-icon-size {
  width: 30px;
  height: 30px;
  background-color: #fff;
  border-radius: 100%;
  text-align: center;
  line-height: 30px;
  font-size: 28px;
  cursor: pointer;
}
.btn-content {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 20px;
}
.btn-content-btn {
  width: 100px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  font-size: 18px;
  background-color: #1890ff;
  color: #fff;
  cursor: pointer;
}
.line-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.line-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
</style>
