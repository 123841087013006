<template>
  <a-popover
    v-model="visible"
    trigger="hover"
    placement="bottomRight"
    overlayClassName="header-notice-wrapper"
    :getPopupContainer="() => $refs.noticeRef.parentElement"
    :autoAdjustOverflow="true"
    :arrowPointAtCenter="true"
    :overlayStyle="{ width: '300px', top: '50px' }"
  >
    <template slot="content">
      <a-spin :spinning="loading">
        <a-tabs>
          <a-tab-pane tab="消息" key="1">
            <a-list>
              <a-list-item>
                <a-list-item-meta :title="`有${info.notSend}个未发货的订单`" description="" @click="$router.push('/orderlist')">
                  <a-avatar style="background-color: white" slot="avatar" :src="ThXAXghbEsBCCSDihZxY"/>
                </a-list-item-meta>
              </a-list-item>
              <a-list-item>
                <a-list-item-meta :title="`有${info.notReceive}个待收货的订单`" description="" @click="$router.push('/orderlist')">
                  <a-avatar style="background-color: white" slot="avatar" :src="OKJXDXrmkNshAMvwtvhu"/>
                </a-list-item-meta>
              </a-list-item>
              <a-list-item>
                <a-list-item-meta :title="`有${info.realCount}个待审核的业务员申请`" description="" @click="$router.push('/idcheck')">
                  <a-avatar style="background-color: white" slot="avatar" :src="kISTdvpyTAhtGxpovNWd"/>
                </a-list-item-meta>
              </a-list-item>
            </a-list>
          </a-tab-pane>
<!--          <a-tab-pane tab="消息" key="2">-->
<!--            123-->
<!--          </a-tab-pane>-->
<!--          <a-tab-pane tab="待办" key="3">-->
<!--            123-->
<!--          </a-tab-pane>-->
        </a-tabs>
      </a-spin>
    </template>
    <span @click="visible=!visible"  class="header-notice" ref="noticeRef" style="padding: 0 18px">
      <a-badge :count="count">
        <a-icon style="font-size: 16px; padding: 4px" type="bell" />
      </a-badge>
    </span>
  </a-popover>
</template>

<script>
import {indexDataCount} from "@/api/modular/mallLiving/index";
export default {
  name: 'HeaderNotice',
  data () {
    return {
      ThXAXghbEsBCCSDihZxY: require('@/assets/ThXAXghbEsBCCSDihZxY.png'),
      OKJXDXrmkNshAMvwtvhu: require('@/assets/OKJXDXrmkNshAMvwtvhu.png'),
      kISTdvpyTAhtGxpovNWd: require('@/assets/kISTdvpyTAhtGxpovNWd.png'),
      loading: false,
      visible: false,
      timer:null,
      count: 0,
      info: {}
    }
  },
  mounted() {
    this.fetchNotice()
    this.timer = setInterval(()=> {
      this.fetchNotice()
    },30000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    fetchNotice () {
      if (!this.visible) {
        this.loading = true
        indexDataCount().then(res=> {
          console.log('indexDataCount',res)
          if(res.success&&res.data) {
            this.info=res.data
            this.count = +res.data.notReceive + +res.data.notSend+ +res.data.realCount
          }
        }).finally(()=> {
          this.loading = false
          
        })
      } else {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="css">
  .header-notice-wrapper {
    top: 50px !important;
  }
</style>
<style lang="less" scoped>
  .header-notice{
    display: inline-block;
    transition: all 0.3s;

    span {
      vertical-align: initial;
    }
  }
</style>
